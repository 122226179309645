import { emptySplitApi as api } from './emptyApi';

export const onboardingApi = api.injectEndpoints({
  endpoints: (build) => ({
    createInstitutionalUser: build.mutation<string, CreateCustodyCompanyRequest>({
      query: (body) => ({ url: `/custody-user/institutional`, method: "POST", body }),
    }),
    createIndividualUser: build.mutation<string, CustodyUserRequest>({
      query: (body) => ({ url: '/custody-user/individual', method: "POST", body }),
    }),
    getUserOnboardingStatus: build.query<OnboardingStatus, OnboardingRegistrarionParams>({
      query: (body) => ({ url: `/custody-user/individual/status`, method: "POST", body }),
    }),
    setupSMAAccount: build.mutation<OnboardingStatus, OnboardingRegistrarionParams>({
      query: (body) => ({ url: `/custody-user/individual/sma`, method: "POST", body }),
    }),
    startKYC: build.mutation<KYCResponse, OnboardingRegistrarionParams>({
      query: (body) => ({ url: `/custody-user/individual/identity/start`, method: "POST", body }),
    }),
    endKYC: build.mutation<OnboardingStatus, KYCValidationToken>({
      query: (body) => ({ url: `/custody-user/individual/identity/finalize`, method: "POST", body }),
    }),
    startHelloSign: build.mutation<HelloSignResponse, OnboardingRegistrarionParams>({
      query: (body) => ({ url: `/custody-user/individual/request-signature`, method: "POST", body }),
    }),
    endHelloSign: build.mutation<null, OnboardingRegistrarionParams>({
      query: (body) => ({ url: `/custody-user/individual/request-signature/update`, method: "POST", body }),
    }),
    createAuthAccount: build.mutation<CreateAuthAccountResponse, OnboardingFinalParams>({
      query: (body) => ({ url: `/custody-user/individual/auth`, method: "POST", body }),
    }),

  }),
  overrideExisting: false,
});

export interface HelloSignResponse {
  signUrl: string
  clientId: string
}
export interface CreateAuthAccountResponse {
  email: string
  message: string
}
export interface KYCValidationToken {
  validationId: string
  email?: string
  registrationId?: string
}
export interface OnboardingStatus {
  status: string
  orderedSteps: string[]
}
export interface KYCResponse extends OnboardingStatus{
  token: string;
  tokenExpiration: string;
}

export interface OnboardingFinalParams {
  email?: string
  registrationId?: string
  password: string
}

export interface OnboardingRegistrarionParams {
  email?: string
  registrationId?: string
}
export interface CreateCustodyCompanyRequest {
  email: string;
  usResident: boolean;
  organizationFullName: string;
  organizationPhoneNumber: string;
  organizationType: string;
  minAccreditedValue: number;
  maxAccreditedValue: number;
  institutionType: string;
}

export interface CustodyUserRequest {
  email: string;
  usResident: boolean;
  minAccreditedValue: number;
  maxAccreditedValue: number;
  organizationType: string;
  token?: string;
}

export default onboardingApi
