import React, { useState } from 'react';
import { Heading } from 'components/atoms/Typography';
import {
  StyledHeading,
  StyledFormWrapper,
  StyledForm,
  StyledAuthenticationContainer,
  StyleAuthenticationFormContainer,
  StyleAuthenticationCarouselContainer,
} from 'styles/components/authentication';
import Footer from 'components/molecules/Footer/Footer';
import styled from 'styled-components';
import RegisterBasicInformation from './RegisterBasicInformation';
import RegisterAccreditedValue from './RegisterAccreditedValue';
import RegisterCompanyForm from './RegisterCompanyForm';
import RegisterError from './RegisterError';
import onboardingApi, { CustodyUserRequest } from 'state/store/onboardingApi';
import RegisterCompanyRegistered from './RegisterCompanyRegistered';
import RegisterUserRegistered from './RegisterUserRegistered';


const emptyInformation: CustodyUserRequest = { email: '', usResident: true, minAccreditedValue: 50000, maxAccreditedValue: 100000, organizationType: 'INDIVIDUAL' };

const steps = ['USER_BASIC_INFORMATION', 'ACCREDITED_VALUE', 'COMPANY_FORM', 'NOT_AVAILABLE', 'COMPANY_REGISTERED', 'USER_REGISTERED'] as const;

const RegisterPage = () => {
  const [information, setInformation] = useState(emptyInformation);
  const [currentStep, setCurrentStep] = useState<typeof steps[number]>('USER_BASIC_INFORMATION');
  const [createUser, createUserMeta] = onboardingApi.useCreateIndividualUserMutation();
  const [turnstileToken, setTurnstileToken] = useState('');


  const saveClient = async () => {
    await createUser({ ...information, token: turnstileToken }).unwrap();
  };

  const back = () => {
    switch (currentStep) {
      case 'ACCREDITED_VALUE':
        setCurrentStep('USER_BASIC_INFORMATION');
        break;
      case 'NOT_AVAILABLE':
        setCurrentStep('ACCREDITED_VALUE');
        break;
      case 'COMPANY_FORM':
        setCurrentStep('ACCREDITED_VALUE')
    }
  };

  const next = async () => {
    switch (currentStep) {
      case 'USER_BASIC_INFORMATION':
        setCurrentStep('ACCREDITED_VALUE');
        break;
      case 'ACCREDITED_VALUE':
        if (information.organizationType === 'INDIVIDUAL') {
          await saveClient();
          if (information.minAccreditedValue < 50000) {
            setCurrentStep('NOT_AVAILABLE');
          } else {
            setCurrentStep('USER_REGISTERED');
          }
        } else {
          setCurrentStep('COMPANY_FORM');
        }
        break;
      case 'COMPANY_FORM':
        if (information.minAccreditedValue < 50000) {
          setCurrentStep('NOT_AVAILABLE');
        } else {
          setCurrentStep('COMPANY_REGISTERED');
        }
        break;
    }
  };


  return (
    <StyledAuthenticationContainer>
      <CarrouselContainer>
        <StyleAuthenticationCarouselContainer />
      </CarrouselContainer>
      <StyleAuthenticationFormContainer>
        <FormContainer>
          <StyledFormWrapper>
            <StyledHeading>
              <Heading variant='h2'>Register for a new Abra account</Heading>
            </StyledHeading>
            <StyledForm>
              {currentStep === 'USER_BASIC_INFORMATION' && (
                <RegisterBasicInformation 
                  information={information} 
                  setInformation={setInformation} 
                  next={next}
                  setTurnstileToken={setTurnstileToken}
                  turnstileToken={turnstileToken} 
                  />
              )}

              {currentStep === 'ACCREDITED_VALUE' && (
                <RegisterAccreditedValue
                  setAccreditedValue={([minAccreditedValue, maxAccreditedValue]) => setInformation({ ...information, minAccreditedValue, maxAccreditedValue })}
                  accreditedValue={[information.minAccreditedValue, information.maxAccreditedValue]}
                  loading={createUserMeta.isLoading}
                  back={back}
                  next={next}
                />
              )}

              {currentStep === 'COMPANY_FORM' && <RegisterCompanyForm back={back} next={next} information={information} />}

              {currentStep === 'NOT_AVAILABLE' && <RegisterError back={back} />}

              {currentStep === 'COMPANY_REGISTERED' && <RegisterCompanyRegistered />}

              {currentStep === 'USER_REGISTERED' && <RegisterUserRegistered />}
            </StyledForm>
          </StyledFormWrapper>
          <Footer />
        </FormContainer>
      </StyleAuthenticationFormContainer>
    </StyledAuthenticationContainer>
  );
};

const CarrouselContainer = styled.div`
  width: 50vw;
  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const FormContainer = styled.div`
  display: flex;
  align-items: stretch;
  width: 90%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2vh;
`;

export default RegisterPage;
